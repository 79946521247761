import { email, InferOutput, object, pipe, string } from 'valibot'

export interface AccountEditFormProps{
  firstName: string
  lastName: string
  pseudo: string
  email: string
}

export const AccountEditSchema = object({
  email: pipe(string(), email()),
  firstName: string(),
  lastName: string(),
  pseudo: string()
})

export type AccountEditFormType = InferOutput<typeof AccountEditSchema>
