import { Component } from 'solid-js'
import styles from '~/components/account-edit-form/account-edit-form.module.scss'
import formStyles from '~/components/forms/styles/simple-form.module.scss'
import { editUser, user } from '~/services/database/users'
import Button from '~/components/button/button'
import AccountPreferredRole from '~/components/account-preferred-role/account-preferred-role'
import { translations } from '~/translations'
import { createForm, getValue, SubmitHandler, valiForm } from '@modular-forms/solid'
import { AccountEditFormProps, AccountEditFormType, AccountEditSchema } from '~/components/account-edit-form/account-edit-form.interfaces'
import TextField from '~/components/fields/text/text'
import FormError from '~/components/form-error/form-error'
import { supabase } from '~/services/supabase'
import { setLoadingState } from '~/services/loading/loading'

const AccountEditForm: Component<AccountEditFormProps> = ({ email, firstName, lastName, pseudo }) => {
  const [form, { Form, Field }] = createForm<AccountEditFormType>({
    validate: valiForm(AccountEditSchema),
    initialValues: {
      firstName,
      lastName,
      pseudo,
      email
    }
  })
  
  const onSubmit: SubmitHandler<AccountEditFormType> = async (values) => {
    setLoadingState({
      loading: true
    })

    // Update regular fields
    await editUser({
      first_name: values.firstName,
      last_name: values.lastName,
      pseudo: values.pseudo,
    })

    // If email has changed, trigger supabase email change
    if (values.email !== user()?.email) {
      const { error } = await supabase.auth.updateUser({ email: values.email })
      if (error) {
        console.error(error)
      } else {
        console.log('Email confirmation sent')
      }
    }

    setLoadingState({
      loading: false
    })
  }

  const emailWillChange = () => {
    const currentEmail = email
    const fieldEmail = getValue(form, 'email')
    return currentEmail !== fieldEmail
  }

  return (
    <Form onSubmit={onSubmit} class={formStyles.form}>
      <Field name="firstName">
        {(field, props) => (
          <TextField
            label={translations().account.profile.firstName}
            placeholder={translations().account.profile.firstName}
            autocomplete='given-name'
            {...field}
            {...props}
          />
        )}
      </Field>

      <Field name="lastName">
        {(field, props) => (
          <TextField
            label={translations().account.profile.lastName}
            placeholder={translations().account.profile.lastName}
            autocomplete='family-name'
            {...field}
            {...props}
          />
        )}
      </Field>

      <Field name="pseudo">
        {(field, props) => (
          <TextField
            label={translations().account.profile.pseudo}
            placeholder={translations().account.profile.pseudo}
            guidelines={translations().account.profile.pseudoGuidelines}
            autocomplete='nickname'
            {...field}
            {...props}
          />
        )}
      </Field>

      <Field name="email">
        {(field, props) => (
          <TextField
            label={translations().account.profile.email}
            type='email'
            placeholder={translations().login.emailPlaceholder}
            autocomplete='email'
            guidelines={emailWillChange() ? translations().updateEmail.description : undefined}
            {...field}
            {...props}
          />
        )}
      </Field>

      <div class={styles.entry}>
        <div class={styles.label}>
          {translations().account.profile.preferredRole}
        </div>
        <div class={styles.value}>
          <AccountPreferredRole />
        </div>
      </div>

      <FormError response={form.response} />
      
      <Button
        type='submit'
      >
        {translations().general.actions.save}
      </Button>
    </Form>
  )
}

export default AccountEditForm
