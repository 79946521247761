import { Component, createEffect, createSignal, untrack } from 'solid-js'
import styles from '~/components/account-interface-edit/account-interface-edit.module.scss'
import LanguageSelection from '~/components/language-selection/language-selection'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import { settings, updateSettings } from '~/services/settings/settings'
import { translations } from '~/translations'

const AccountInterfaceEdit: Component = () => {
  // Theme handling
  const [themes, setThemes] = createSignal<PillOption[]>([
    {
      label: translations().account.profile.theme.light,
      value: 'light',
      checked: !settings().darkmode
    },
    {
      label: translations().account.profile.theme.dark,
      value: 'dark',
      checked: settings().darkmode
    }
  ])

  createEffect(() => {
    const isDarkmode = themes().find(theme => theme.checked)?.value === 'dark'
    untrack(() => {
      updateSettings({
        darkmode: isDarkmode
      })
    })
  })

  return (
    <>
      <div class={styles.entry}>
        <div class={styles.label}>
          {translations().account.profile.language}
        </div>
        <div class={styles.value}>
          <LanguageSelection />
        </div>
      </div>

      <div class={styles.entry}>
        <div class={styles.label}>
          {translations().account.profile.interface}
        </div>
        <div class={styles.value}>
          <TogglePill
            options={themes()}
            setOptions={setThemes}
          />
        </div>
      </div>
    </>
  )
}

export default AccountInterfaceEdit
