import { getUserToken } from '~/services/supabase'
import { APIResponse } from '~/types/api/response'

const getPaymentMethodUpdateUrl = async () => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/payment-method-update', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })
    const response = await data.json() as APIResponse<string>
    return response?.result
  }
}

export {
  getPaymentMethodUpdateUrl
}