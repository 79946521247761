import { Component, Show } from 'solid-js'
import styles from '~/components/account/account.module.scss'
import { supabase } from '~/services/supabase'
import { useNavigate } from '@solidjs/router'
import { translations } from '~/translations'
import DashboardUser from '~/components/dashboard-user/dashboard-user'
import Button from '~/components/button/button'
import LogoutIcon from '~/assets/icons/log-out.svg'
import AccountEditForm from '~/components/account-edit-form/account-edit-form'
import AccountInterfaceEdit from '~/components/account-interface-edit/account-interface-edit'
import { user } from '~/services/database/users'
import { AccountEditFormProps } from '~/components/account-edit-form/account-edit-form.interfaces'
import AccountUpdatePaymentMethod from '~/components/account-update-payment-method/account-update-payment-method'
import AccountInvoices from '~/components/account-invoices/account-invoices'

const Account: Component = () => {
  const navigate = useNavigate()
  
  const onLogout = () => {
    supabase.auth.signOut().finally(() => {
      navigate('/account/login')
    })
  }

  const profileFields = () => {
    let result: AccountEditFormProps | false = false
    const userData = user()
    if(userData){
      result = {
        firstName: userData.first_name ?? '',
        lastName: userData.last_name ?? '',
        pseudo: userData.pseudo ?? '',
        email: userData.email,
      }
    }
    return result
  }
  
  return (
    <div class={styles.container}>
      <aside class={styles.aside}>
        <DashboardUser />
        
        <AccountInterfaceEdit />

        <Button
          icon={LogoutIcon}
          style='borderless'
          onClick={onLogout}
        >
          {translations().account.profile.logout}
        </Button>
      </aside>
      
      <div class={styles.group}>
        <div class={styles.title}>
          {translations().account.profile.profile}
        </div>
        <Show when={profileFields()}>
          {(fields) => (
            <AccountEditForm {...fields()} />
          )}
        </Show>
      </div>
      
      <div class={styles.group}>
        <div class={styles.title}>
          {translations().account.invoices.invoices}
        </div>
        <AccountInvoices />
        <AccountUpdatePaymentMethod />
      </div>
    </div>
  )
}

export default Account
