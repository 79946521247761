import Stripe from 'stripe'
import { getUserToken } from '~/services/supabase'
import { APIResponse } from '~/types/api/response'

const getInvoices = async () => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/invoices', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })
    const result = await data.json() as APIResponse<Stripe.Response<Stripe.ApiList<Stripe.Invoice>>>
    return result?.result.data ?? []
  }
}

export {
  getInvoices
}