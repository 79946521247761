import { Component } from 'solid-js'
import Button from '~/components/button/button'
import { translations } from '~/translations'
import UpdateIcon from '~/assets/icons/edit-alt.svg'
import { setLoadingState } from '~/services/loading/loading'
import { getPaymentMethodUpdateUrl } from '~/services/stripe/payment-method-update'

const AccountUpdatePaymentMethod: Component = () => {
  const onPaymentMethodUpdate = async () => {
    setLoadingState({
      loading: true
    })

    const url = await getPaymentMethodUpdateUrl()

    if(url){
      window.location.href = url
    }

    setLoadingState({
      loading: false
    })
  }

  return (
    <>
      <Button
        onClick={onPaymentMethodUpdate}
        icon={UpdateIcon}
      >
        {translations().account.invoices.updatePaymentMethod}
      </Button>
    </>
  )
}

export default AccountUpdatePaymentMethod
