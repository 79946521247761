import { Component, createResource, For } from 'solid-js'
import styles from '~/components/account-invoices/account-invoices.module.scss'
import RowButton from '~/components/row-button/row-button'
import { translations } from '~/translations'
import DownloadIcon from '~/assets/icons/download.svg'
import { getInvoices } from '~/services/stripe/invoices'
import Stripe from 'stripe'
import { getPlanFromId } from '~/static/stripe'

const AccountInvoices: Component = () => {
  const [invoices] = createResource(getInvoices)

  const getInvoiceLabel = (invoice: Stripe.Invoice) => {
    let name = translations().account.invoices.invoices
    if (typeof invoice.subscription !== 'string') {
      const id = invoice.subscription?.items.data[0].plan.product as string
      name = `${translations().subscriptions.plans.brand} ${getPlanFromId(id)?.title ?? name}`
    }
    return name
  }

  return (
    <div class={styles.accountInvoices}>
      <For each={invoices.latest}>
        {(invoice) => (
          <div class={styles.invoiceRow}>
            <div class={styles.id}>
              {getInvoiceLabel(invoice)}
            </div>
            <div class={styles.date}>
              {new Date(invoice.created * 1000).toLocaleDateString()}
            </div>
            <div class={styles.download}>
              <RowButton
                icon={DownloadIcon}
                label={translations().general.actions.download}
                href={invoice.invoice_pdf ?? ''}
              />
            </div>
          </div>
        )}
      </For>
    </div>
  )
}

export default AccountInvoices
